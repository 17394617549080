// Imports
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";

// CSS
import "./css/Header.css";

// Components
import Logo from "../assets/images/bb-logo-header.svg";
import GlobalIcon from "../assets/images/global.svg";
import Select from "./SelectV2";

// ======== Component ========
const Header = ({ slug }) => {
    const {
        allWpReformerSafety: { languages },
    } = useStaticQuery(graphql`
        query Languages {
            allWpReformerSafety {
                languages: edges {
                    node {
                        language
                        slug
                    }
                }
            }
        }
    `);

    const options = languages
        .map(({ node: languageObj }) => languageObj) // {language, slug}
        .sort((a, b) => a.language.localeCompare(b.language))
        .reduce((acc, { language, slug }) => {
            const option = {
                label: language,
                value: language,
                slug: slug,
            };
            language === "English" ? acc.unshift(option) : acc.push(option);
            return acc;
        }, []);

    const initialState = options.find((option) => option.slug === slug) || options[0];
    const [selectedLanguage, setSelected] = useState(initialState);

    useEffect(() => {
        if (!slug) {
            navigate(`/${selectedLanguage?.slug}`);
        }
    }, [selectedLanguage, slug]);

    return (
        <header className={`header`}>
            <div className="header__container">
                <a
                    href="https://www.pilates.com"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Logo className={"logo"} />
                </a>
                <div className="header__select">
                    <GlobalIcon />
                    <Select
                        value={selectedLanguage}
                        onChange={(option) => setSelected(option)}
                        options={options}
                        customOption={({ slug, children }) => <Link to={`/${slug}`}> {children} </Link>}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
