// Imports
import React from "react";
import PropTypes from "prop-types";

// Global CSS
import "../assets/css/globalReset.css";
import "../assets/css/globalFonts.css";
import "../assets/css/globalStyles.css";

// CSS
import "./css/Layout.css";

// Components
import Header from "./Header";
import Footer from "./Footer";
import Seo from "./SEO";

// Hooks
import useContactFormModal from "../hooks/useContactFormModal";

// ======== Component ========
const Layout = ({ children, params, data = {}, location, ...rest }) => {
    const { wpReformerSafety } = data;
    const { setOpen, ContactFormModal } = useContactFormModal();
    return (
        <main className={`layout`}>
            <Seo
                title={"Reformer Safety"}
                desc={"Reformer safety page"}
            />
            <Header slug={params.slug} />
            <div className={"layoutMain"}>{children}</div>
            {wpReformerSafety && (
                <Footer
                    contactUsText={wpReformerSafety.contactUsText}
                    contactUsLinkText={wpReformerSafety.contactUsLinkText}
                    setModal={setOpen}
                />
            )}
            {wpReformerSafety && (
                <ContactFormModal
                    title={wpReformerSafety.contactUsTitle}
                    form={{
                        firstName: wpReformerSafety.firstName,
                        lastName: wpReformerSafety.lastName,
                        emailAddress: wpReformerSafety.emailAddress,
                        emailValue: new URLSearchParams(location.search).get("email") || "",
                        message: wpReformerSafety.contactUsMessage,
                        submit: wpReformerSafety.submit,
                    }}
                    confirmation={{
                        title: wpReformerSafety.contactUsConfirmationTitle,
                        content: wpReformerSafety.contactUsConfirmationContent,
                    }}
                    privacy={wpReformerSafety.privacy}
                />
            )}
        </main>
    );
};

// Prop validation
Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    desc: PropTypes.string,
};

Layout.defaultProps = {
    title: "Balanced Body",
};

export default Layout;
