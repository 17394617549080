import React, { useState } from "react";

import Modal from "../components/Modal";
import ContactForm from "../components/ContactForm";

function useContactFormModal() {
    const [open, setOpen] = useState(false);

    const ContactFormModal = ({ ...props }) => (
        <Modal
            contentLabel="contact form"
            isOpen={open}
            onRequestClose={() => setOpen(false)}
        >
            <ContactForm {...props} />
        </Modal>
    );

    return { setOpen, ContactFormModal };
}

export default useContactFormModal;
