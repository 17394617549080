// Imports
import React from "react";

// CSS
import "./css/Footer.css";

// ======== Component ========
const Footer = ({ setModal, contactUsText, contactUsLinkText }) => {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__groups">
                    <ul className="footer__group footer__group--one">
                        {/* <li>
                            <a href="/">Legal</a>
                        </li> */}
                        <li>
                            <a
                                href="https://www.pilates.com/company/balanced-body-privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy
                            </a>
                        </li>
                        <li>{`©${new Date().getFullYear()} Balanced Body`}</li>
                    </ul>
                    <div className="footer__group footer__group--two">
                        <p>
                            {contactUsText}{" "}
                            <span
                                role="button"
                                aria-pressed="false"
                                onClick={() => setModal(true)}
                            >
                                {contactUsLinkText}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
