import React from "react";
import Select, { components } from "react-select";
import { FiChevronDown } from "react-icons/fi";

import "./css/Select.css";

//  The 'floating' label

const Label = ({ isFloating, children }) => {
    return (
        <span className={`select__label ${isFloating ? "select__labelFloating" : null}`}>
            {children}
        </span>
    );
};

// Adding the floaitng label above the Control

const Control = ({ isFocused, hasValue, selectProps, menuIsOpen, ...props }) => {
    return (
        <>
            <Label isFloating={menuIsOpen || hasValue}>
                {selectProps?.label ? selectProps.label : "Select"}
            </Label>
            <components.Control menuIsOpen={menuIsOpen} {...props} />
        </>
    );
};

// Modify Dropdown indicator

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FiChevronDown />
        </components.DropdownIndicator>
    );
};

// Custom Select

const CustomSelect = (props) => {
    return (
        <Select
            {...props}
            className={`select ${props.readOnly && 'readOnly'}`}
            classNamePrefix={"select"}
            components={{
                Control,
                DropdownIndicator: !props.readOnly && DropdownIndicator,
            }}
            isSearchable={false}
            placeholder={""}
        />
    );
};

export default CustomSelect;
