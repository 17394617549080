import React from "react";
import classNames from "classnames";
import Select, { components } from "react-select";
import { FiChevronDown } from "react-icons/fi";

// CSS
import "./css/SelectV2.css";

const ns = `select-v2`;

// Adding the floaitng label above the Control

const Control = ({ hasValue, selectProps, ...props }) => {
    return (
        <>
            {selectProps?.label && <div className={`${ns}__label`}>{selectProps.label}</div>}
            <components.Control {...props} />
        </>
    );
};

const Option = (props) => {
    const { customOption: CustomOption } = props.selectProps;
    const { data, children } = props;
    return CustomOption ? (
        <CustomOption slug={data.slug}>
            <components.Option {...props}>{children}</components.Option>
        </CustomOption>
    ) : (
        <components.Option {...props}>{children}</components.Option>
    );
};

// Modify Dropdown indicator

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FiChevronDown />
        </components.DropdownIndicator>
    );
};

// Modify Dropdown indicator

const SelectContainer = ({ children, ...props }) => {
    return (
        <components.SelectContainer {...props}>
            <div className={`${ns}__wrap`}>{children}</div>
        </components.SelectContainer>
    );
};

const SelectV2 = (props) => {
    const rootClassnames = classNames({
        [`${ns}`]: true,
    });

    return (
        <Select
            {...props}
            className={rootClassnames}
            classNamePrefix={ns}
            components={{
                Control,
                DropdownIndicator,
                SelectContainer,
                Option,
            }}
            isSearchable={false}
            placeholder={""}
        />
    );
};

export default SelectV2;
