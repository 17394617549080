import React from "react";

// CSS
import "./css/Button.css";

function Button({ children, ...props }) {
    return <button className="button" {...props}>{children}</button>;
}

export default Button;
