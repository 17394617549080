import { useStaticQuery, graphql } from "gatsby";

function useCountries() {
    const data = useStaticQuery(graphql`
        {
            allCountriesAndUsStates {
                nodes {
                    countries {
                        label
                        value
                    }
                    usStates {
                        value
                        label
                    }
                }
            }
        }
    `);

    const { countries: countryOptions, usStates } = data.allCountriesAndUsStates.nodes[0];
    return {countryOptions, usStates};
}

export default useCountries;
