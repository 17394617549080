import React, { useState } from "react";

// CSS
import "./css/ContactForm.css";

// Hooks
import useCountries from "../hooks/useCountries";

// Components
import Logo from "../assets/images/logo-bb-icon.svg";
import Button from "./Button";
import Select from "./Select";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
    firstName: yup.string().required("*Requred"),
    lastName: yup.string().required("*Requred"),
    emailAddress: yup.string().email("*Invalid Email.").required("*Required."),
    message: yup.string().required("*Required"),
    country: yup.string().required("*Required"),
});

function ContactForm({ title, form = {}, privacy, confirmation = {} }) {
    const [submitted, setSubmitted] = useState(false); // Api Submit

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitSuccessful },
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            emailAddress: form.emailValue,
        },
    });

    const onSubmit = (data) => {
        fetch(process.env.GATSBY_CONTACT_US_API, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res.ok && res.status === 200) return setSubmitted(true);
                setSubmitted("failure");
            })
            .catch((err) => {
                setSubmitted("failure");
                console.error(err);
            });
    };

    const { countryOptions } = useCountries();

    const isSubmitting = isSubmitSuccessful && !submitted;
    const isErrors = !!Object.keys(errors).length;
    const disableButton = isSubmitting || isErrors || submitted;

    const btnTxt = () => {
        switch (true) {
            case !!submitted:
                return submitted === true ? "Submitted Successfully" : "Technical issue, Try again later!";
            case isSubmitting:
                return "Submitting...";
            case isErrors:
                return "please complete missing fields";
            default:
                return form.submit;
        }
    };

    return (
        <div className="contact-form">
            <div className="contact-form__container">
                <div className="contact-form__header">
                    <Logo className="contact-form__header-logo" />
                    <h2 className="contact-form__header-title">{submitted === true ? confirmation.title : title}</h2>
                </div>
                {submitted === true ? (
                    <div className="contact-form__confirmation-content">
                        <div
                            className="contact-form__confirmation-content-wrapper"
                            dangerouslySetInnerHTML={{ __html: confirmation.content }}
                        />
                    </div>
                ) : (
                    <form
                        className={`contact-form__form ${submitted === true && "contact-form__form--submitted"}`}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="contact-form__form-wrapper">
                            <div
                                className={`contact-form__form-group ${
                                    errors.firstName && "contact-form__form-group--error"
                                }`}
                            >
                                <label htmlFor="firstName">
                                    <input
                                        {...register("firstName")}
                                        type={`text`}
                                        id="firstName"
                                        value={watch("firstName") || ""}
                                        dir="auto"
                                    />
                                    <span>{form.firstName}</span>
                                </label>
                                {/* {errors.firstName?.message && <p>{errors.firstName.message}</p>} */}
                            </div>
                            <div
                                className={`contact-form__form-group ${
                                    errors.lastName && "contact-form__form-group--error"
                                }`}
                            >
                                <label htmlFor="lastName">
                                    <input
                                        {...register("lastName")}
                                        type={`text`}
                                        id="lastName"
                                        value={watch("lastName") || ""}
                                        dir="auto"
                                    />
                                    <span>{form.lastName}</span>
                                </label>
                                {/* {errors.lastName?.message && <p>{errors.lastName.message}</p>} */}
                            </div>
                            <div
                                className={`contact-form__form-group ${
                                    errors.emailAddress && "contact-form__form-group--error"
                                }`}
                            >
                                <label htmlFor="emailAddress">
                                    <input
                                        {...register("emailAddress")}
                                        type={`text`}
                                        id="emailAddress"
                                        value={watch("emailAddress") || ""}
                                        dir="auto"
                                    />
                                    <span>{form.emailAddress}</span>
                                </label>
                                {/* {errors.emailAddress?.message && <p>{errors.emailAddress.message}</p>} */}
                            </div>
                            <div
                                className={`contact-form__select-group ${
                                    errors.country && "contact-form__select-group--error"
                                }`}
                            >
                                <Controller
                                    name={"country"}
                                    control={control}
                                    render={({ field: { onChange, value, ref, ...props } }) => {
                                        return (
                                            <Select
                                                {...props}
                                                innerRef={ref}
                                                value={
                                                    countryOptions.find((c) => {
                                                        return c.value === value;
                                                    }) || ""
                                                }
                                                label={form.country}
                                                options={countryOptions}
                                                onChange={(selectedOption) => {
                                                    onChange(selectedOption.value);
                                                }}
                                            />
                                        );
                                    }}
                                />
                                {/* {errors.country?.message && <p>{errors.country.message}</p>} */}
                            </div>
                            <div
                                className={`contact-form__form-group ${
                                    errors.message && "contact-form__form-group--error"
                                }`}
                            >
                                <textarea
                                    {...register("message")}
                                    type={`text`}
                                    id="message"
                                    dir="auto"
                                    placeholder={form.message}
                                />
                                {/* {errors.message?.message && <p>{errors.message.message}</p>} */}
                            </div>
                            <Button
                                type="submit"
                                disabled={disableButton}
                            >
                                {btnTxt()}
                            </Button>
                        </div>
                        <div
                            className="contact-form__privacy"
                            dangerouslySetInnerHTML={{ __html: privacy }}
                        />
                    </form>
                )}
            </div>
        </div>
    );
}

export default ContactForm;
